import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Privacidad = () => {
  return (
    <Layout>
      <Seo
        title='Aviso de privacidad - MECI'
        description='Usted tiene derecho a saber en que se utiliza la información que proporciona. Puede consultar los términos y condiciones de uso en esta página.'
      />
      <div className='col-12 col-md-10 mx-auto my-5'>
        <h1 className='fw-bold'>AVISO DE PRIVACIDAD</h1>
        <p className='lead'>TUS DATOS PROTEGIDOS</p>
        <p>
          Con fundamento en los artículos 15 y 16 de la Ley Federal de
          Protección de Datos Personales en Posesión de Particulares hacemos de
          tu conocimiento que Kubrelam es una empresa radicada en Monterrey,
          México, la cual por sí misma ó por conducto de cualquiera de sus
          empresas relacionadas, filiales ó subsidiarias, es responsable de
          recabar tus datos personales, del uso que se le dé a los mismos y de
          su protección.
          <br />
          Tus datos serán usados para:
        </p>
        <ul>
          <li>Darte el servicio que hayas solicitado.</li>
          <li>
            Notificarte sobre nuevos servicios o productos que puedan ser de tu
            interés.
          </li>
          <li>
            Comunicarte cualquier novedad acerca de nuestros servicios ó
            productos.
          </li>
          <li>Para que puedas (y podamos) evaluar nuestro servicio.</li>
        </ul>
        <p>Para estos fines podemos haber solicitado:</p>
        <ul>
          <li>Nombre completo</li>
          <li>Teléfono fijo y/o celular</li>
          <li>Correo electrónico</li>
          <li>El nombre de tu empresa o negocio </li>
          <li>Domicilio de Oficina</li>
          <li>Puesto</li>
        </ul>
        <p>
          Es importante informarte que tienes derecho al acceso, rectificación y
          cancelación de tus datos personales.
        </p>
        <p className='fs-6'>
          Importante: Cualquier modificación a este Aviso de Privacidad será
          notificada a través de nuestro sitio web ó te enviaremos un correo
          electrónico para que estés enterado. Fecha de última actualización:
          01/09/2016.
        </p>
      </div>
    </Layout>
  )
}

export default Privacidad
